import React, { useEffect, useState } from "react";

import { Card } from "react-bootstrap";

import styled from "styled-components";
import { SignInButton } from "./SignInButton";
import axios from "axios";
import XMLParser from "react-xml-parser";

const MyContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyCard = styled(Card)`
  width: 18rem;
  /* height: 10rem; */
  margin-top: -80px;
`;

const WelcomeMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  /* position: absolute; */
  /* border:1px solid black; */
  width: 100%;
`;

const SignIn = () => {
  const getLogos = async () => {
    const url =
      "https://geodesicpublicresources.blob.core.windows.net/school-logos?restype=container&comp=list&include=metadata";

    const res = await axios.get(url);

    const xml = new XMLParser().parseFromString(res.data);
    // console.log(xml);
    let correctUrl = "";
    try {
      let possibleOptions = xml
        .getElementsByTagName("Metadata")
        .map((entry) => {
          return {
            schoolUrl: entry.children[0].value,
            thisUrl: entry.children[1].value,
          };
        });

      console.log(possibleOptions);
      console.log(window.location.href)

      correctUrl = possibleOptions.filter((entry) =>
        window.location.href.includes(entry.schoolUrl)
      )[0].thisUrl;

      console.log('correct URL FOUND!',possibleOptions.filter((entry) =>
        window.location.href.includes(entry.schoolUrl)
      )[0].schoolUrl,correctUrl)
    } catch (err) {
      console.log(err);
      correctUrl =
        "https://geodesicpublicresources.blob.core.windows.net/school-logos/Geodesic.png";
    }
    setLogoSrc(correctUrl);
  };

  useEffect(() => {
    getLogos();
  }, []);

  const [logoSrc, setLogoSrc] = useState("");
  return (
    <MyContainer>
      <MyCard style={{ width: "40rem" }}>
        <Card.Body>
          <div style={{ width: "100%", position: "relative" }}>
            <WelcomeMessage>The Hub</WelcomeMessage>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img height={200} src={logoSrc} alt="" />
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <SignInButton />
          </div>
        </Card.Footer>
      </MyCard>
    </MyContainer>
  );
};

export default SignIn;
